/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */

/** @jsxImportSource @emotion/react */
import React from "react";

import { formatInTimeZone } from "date-fns-tz";

import { Box, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";

import Loading from "../../../Components/Loading";
import { IMinReconciliationReport } from "./MinReconciliationReportInterfaces";
import MinReconciliationReportStatus from "./MinReconciliationReportStatus";
import { IMinReconciliationReportStatusResponse, getMinReconciliationReportStatus } from "./minReconciliationAdapter";

interface IMinReconciliationReportResults {
  report: IMinReconciliationReport;
}

/**
 * Component for reconciliation report results. This should contain
 */
export default function MinReconciliationReportDetails(props: IMinReconciliationReportResults): JSX.Element {
  const { report } = props;
  const { id } = report;
  const [data, setData] = React.useState<IMinReconciliationReportStatusResponse | undefined>(undefined);
  const [loading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    /**
     * Fetches the status for the reconciliation report.
     */
    async function getReportStatus() {
      setLoading(true);
      const response = await getMinReconciliationReportStatus(id);
      setData(response.data);
      setLoading(false);
    }
    void getReportStatus();
  }, [id]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <Box mb={2} ml={2} mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Report Date</Typography>
            <Typography>
              {formatInTimeZone(new Date(report.createdDate), "America/Boise", "M/d/yyyy h:mm a zzz")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">MERS Org Id</Typography>
            <Typography>{report.mersOrgId}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">MINs Processed</Typography>
            <Typography>{report.numberOfMinsProcessed}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Total Errors</Typography>
            <Typography>{report.totalErrors}</Typography>
          </Grid>
        </Grid>
      </Box>

      {data != null && (
        <>
          <Grid container spacing={1} ml={1}>
            {data.details.map((details) => (
              <MinReconciliationReportStatus details={details} key={details.type} id={id} />
            ))}
          </Grid>
        </>
      )}
    </div>
  );
}
