import * as React from "react";

import DownloadIcon from "@mui/icons-material/CloudDownload";
import { Alert, AlertTitle, Grid, Link, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { IMinReconciliationReportStatusDetailsResponse } from "./minReconciliationAdapter";

const useStyles = makeStyles({
  alert: {
    "&:hover": {
      cursor: "pointer",
    },
  },
});

interface IMersGroupStatusProps {
  details: IMinReconciliationReportStatusDetailsResponse;
  id: string;
}
/**
 * Component that displays a status group for the reconciliation report.
 */
function MinReconciliationReportStatus({ details, id }: IMersGroupStatusProps): JSX.Element {
  const classes = useStyles();
  const query = `?status=${details.type}`;

  return (
    <Grid item xs={3} xl={2}>
      <Tooltip title={details.detailedDescription}>
        <Link href={`/api/v1/mers/reconciliation-reports/${id}/export${query}`} underline="none" target="_blank">
          <Alert
            action={<DownloadIcon />}
            className={classes.alert}
            severity={details.severity}
            variant="filled"
            style={{ alignItems: "start", height: "100%" }}
          >
            <AlertTitle>{details.count ?? 0}</AlertTitle>
            {details.description}
          </Alert>
        </Link>
      </Tooltip>
    </Grid>
  );
}

export default MinReconciliationReportStatus;
